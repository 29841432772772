import { css } from "@emotion/react";
import {
  generateUniqueId,
  Button,
  withDefaultProps,
  ListStack,
  Link,
  Box,
  Typography,
  Icon,
} from "@wsui/base";
import { FormInputSubmitField } from "@wsui/form";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ModuleWrapper from "./ModuleWrapper.jsx";

export default function WasteCalendarModule({ title, description }) {
  const { t } = useTranslation();

  const MAX_NUMBER_OF_DATES_TO_SHOW = 6;

  const name = "query";
  const label = t("wsui.algolia.searchBoxLabel");
  const placeholder = t("wasteCalendar.placeholder");
  const submitLabel = t("wsui.algolia.searchBoxSubmitLabel");

  const [id] = useState(() => `search-box-${generateUniqueId()}`);
  const [data, setData] = useState({});
  const [query, setQuery] = useState("");
  const [error, setError] = useState(false);
  const [pending, setPending] = useState(false);

  const doQuery = async (query) => {
    try {
      setData({});
      setQuery(query);
      setError(false);
      setPending(true);

      const result = await fetch(
        `/api/wastecalendar/search?query=${encodeURIComponent(query)}`,
      );
      const json = await result.json();
      const processed =
        json.fp && json.fp.length > 0
          ? json.fp
              .map((location) => {
                let trashDays = location.Exec.DatumFormaterat.map(
                  (date, index) => {
                    return {
                      date,
                      type: getTypeLabel(location.Exec.AvfallsTyp[index]),
                      changed: location.Exec.Andrad[index] === 1,
                    };
                  },
                );
                trashDays = trashDays.slice(
                  0,
                  Math.min(MAX_NUMBER_OF_DATES_TO_SHOW, trashDays.length),
                );

                return {
                  city: location.Ort,
                  address: location.Adress,
                  id: location.id,
                  trashDays,
                };
              })
              .filter((location) => location.trashDays.length > 0)
          : [];

      setPending(false);
      setData(processed);
    } catch (error) {
      setPending(false);
      setError(true);
    }
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case "Hårdplastförpackningar":
        return "Hårdplast";
      case "Metallförpackningar":
        return "Metallförp.";
      case "Pappersförpackningar":
        return "Pappersförp.";
    }

    return type;
  };

  return (
    <ModuleWrapper title={title} description={description}>
      <Box padding={4}>
        <Formik
          initialValues={{ [name]: "" }}
          onSubmit={async ({ [name]: query }) => {
            await doQuery(query);
          }}
        >
          <Form
            css={css`
              position: relative;
            `}
          >
            <FormInputSubmitField
              label={label}
              hideLabel
              id={id}
              name={name}
              placeholder={
                placeholder || t(["wsui.algolia.searchBoxPlaceholder"])
              }
              css={css`
                --form-input-width: var(--search-form-input-width, 100%);
                --form-input-padding: var(--search-form-padding-default, 1rem);
                --form-input-border: var(--search-form-border, none);
                --form-input-border-radius: var(
                  --search-form-border-radius,
                  0.25rem
                );
                --form-input-background: var(--search-form-background, #ffffff);
                --form-input-font-family: var(
                  --search-form-font-family,
                  inherit
                );
                --form-input-font-size: var(--search-form-font-size, 100%);
                --form-input-color: var(--search-form-surface, #000000);
              `}
              components={{
                SubmitButton: withDefaultProps(Button, {
                  label: submitLabel,
                  variant: "submit",
                  style: {
                    paddingBlock: 0,
                  },
                }),
              }}
              //{...restProps}
            />
          </Form>
        </Formik>
      </Box>

      {pending && (
        <Box
          color={"turquoise.100"}
          css={css`
            margin-top: 24px;
            width: fit-content;
          `}
        >
          <Typography variant="body">{t("loading")}</Typography>
        </Box>
      )}

      {error && (
        <Box
          color={"red.438"}
          css={css`
            margin-top: 24px;
            width: fit-content;
          `}
        >
          <Typography variant="body">
            {t("wasteCalendar.errorMessage")}
          </Typography>
        </Box>
      )}

      {data && data.length === 0 && (
        <Box
          color={"turquoise.100"}
          css={css`
            margin-top: 24px;
            width: fit-content;
          `}
        >
          <Typography variant="body">{t("wasteCalendar.noResults")}</Typography>
        </Box>
      )}

      {data && data.length > 0 && (
        <div
          css={css`
            padding-top: 12px;
          `}
        >
          <Typography variant="body">
            <strong>{data.length}</strong> {t("wasteCalendar.hitsFor")}{" "}
            <strong>{query}</strong>
          </Typography>
          <ListStack
            spacing={6}
            css={css`
              padding-top: 36px;
            `}
          >
            {data.map((location, index) => (
              <Box
                key={`wastelocation-${index}`}
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  @media only screen and (max-width: 600px) {
                    flex-direction: column;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <div>
                    <Typography variant="h2">{location.address}</Typography>
                    <Typography
                      variant="h4"
                      css={css`
                        padding-top: 8px;
                      `}
                    >
                      {location.city}
                    </Typography>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 12px;
                      padding-top: 24px;
                      @media only screen and (max-width: 600px) {
                        padding-bottom: 24px;
                      }
                    `}
                  >
                    <Link
                      type={"external"}
                      href={`/api/wastecalendar/calendar?query=${encodeURIComponent(
                        query,
                      )}&id=${
                        location.id
                      }&calendar_type=ical&action=fetchDataFromFetchPlannerCalendar&level=ajax&type=json`}
                      rel="noreferrer"
                    >
                      {t("wasteCalendar.downloadCalendar")}
                    </Link>
                    <Link
                      type={"external"}
                      href={`/api/wastecalendar/calendar?query=${encodeURIComponent(
                        query,
                      )}&id=${
                        location.id
                      }&calendar_type=pdf&action=fetchDataFromFetchPlannerCalendar&level=ajax&type=json`}
                      target={"_blank"}
                      rel="noreferrer"
                    >{`${t("wasteCalendar.downloadPdf")} ${
                      new Date().getMonth() === 11
                        ? new Date().getFullYear() + 1
                        : new Date().getFullYear()
                    }`}</Link>
                  </div>
                </div>
                <ListStack divider={true} spacing={4}>
                  {location.trashDays.map((trashDay, index) => (
                    <div
                      key={`locationInfo-${index}`}
                      css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        min-width: 300px;
                        @media only screen and (max-width: 600px) {
                          min-width: 0px;
                        }
                      `}
                    >
                      <Typography variant="h4">{trashDay.type}</Typography>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          gap: 8px;
                        `}
                      >
                        <Typography variant="body">{trashDay.date}</Typography>
                        {trashDay.changed && (
                          <Icon name="alert" color="red.438" size="1.5rem" />
                        )}
                      </div>
                    </div>
                  ))}
                </ListStack>
              </Box>
            ))}
          </ListStack>
        </div>
      )}
    </ModuleWrapper>
  );
}
